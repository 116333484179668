<template>
  <a-layout>
    <a-layout-sider width="260">
      <a-menu
        mode="inline"
        :default-selected-keys="[0]"
        :default-open-keys="['sub']"
        v-model="active"
        @select="onSelectMenuItem"
      >
        <a-sub-menu key="sub" :title="title">
          <a-menu-item v-for="(item, index) in routes" :key="index">
            <a-icon :type="item.meta.icon" />
            {{ item.meta.title }}
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <keep-alive include="Index" :max="1">
      <router-view :type="type" :status="status" />
    </keep-alive>
  </a-layout>
</template>

<script>
export default {
  name: 'SubLayout',
  props: {
    title: String,
    routes: Array,
    type: String
  },
  data() {
    return {
      active: [0],
      status: 0
    };
  },
  methods: {
    async onSelectMenuItem({ key }) {
      const name = this.routes[key].children[0].name;
      this.active = [key];
      this.status = key;
      await this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout {
  padding: 32px;

  &-sider {
    padding: 24px 0;
    background-color: #fff;
  }
}
</style>
