<template>
  <div>
    <a-form-model-item
      :label="meta.label"
      v-for="(meta, i) in item.metas"
      :key="i"
      :prop="`${item.prop}.${index}.${meta.prop}`"
      :rules="rules(meta)"
    >
      <a-input
        v-if="meta.type == 'text'"
        v-model="model[item.prop][index][meta.prop]"
        v-bind="meta"
      />
      <a-textarea
        v-if="meta.type == 'textarea'"
        v-model="model[item.prop][index][meta.prop]"
        v-bind="meta"
      />
      <a-input-number
        v-else-if="meta.type == 'number'"
        v-bind="meta"
        v-model="model[item.prop][index][meta.prop]"
        style="width: 100%"
      />
      <a-date-picker
        v-else-if="meta.type == 'date'"
        v-bind="meta"
        v-model="model[item.prop][index][meta.prop]"
        style="width: 100%"
      />
      <a-select
        v-else-if="meta.type == 'select'"
        v-model="model[item.prop][index][meta.prop]"
        v-bind="meta"
      >
        <a-select-option v-for="(opt, i) in meta.optoins" :key="i" v-bind="opt">
          {{ opt.label }}
        </a-select-option>
      </a-select>
      <app-upload
        v-else-if="meta.type == 'files'"
        v-model="model[item.prop][index][meta.prop]"
        no-show-header
      />
    </a-form-model-item>
  </div>
</template>

<script>
export default {
  name: 'AppFormItem',
  props: {
    index: Number,
    item: Object,
    model: Object
  },
  methods: {
    rules(meta) {
      if (meta.required) {
        return { required: true, message: `${meta.label}是必填的` };
      } else {
        return undefined;
      }
    }
  }
};
</script>
