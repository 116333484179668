import * as dd from 'dingtalk-jsapi';
import setting from '@/setting';

async function install(Vue) {
  Vue.prototype.$sign = async () => {
    const { timeStamp, nonceStr, signature } = await Vue.prototype.$http.get(
      '/pcWeb/sign',
      {
        params: { url: location.href }
      }
    );
    dd.config({
      corpId: setting.corpId,
      agentId: setting.agentId,
      timeStamp,
      nonceStr,
      signature,
      type: 0,
      jsApiList: [
        'biz.util.uploadAttachment',
        'biz.cspace.preview',
        'biz.contact.complexPicker'
      ]
    });
  };
}

export default install;
