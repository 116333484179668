import Vue from 'vue';
import router from '@/router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import sign from '@/plugins/sign';
import { notification } from 'ant-design-vue';
import Layout from './Layout';
import SubLayout from './SubLayout';
import AppUpload from './AppUpload';
import AppFormItem from './AppFormItem';
import AppSteps from './AppSteps';
import AppActions from './AppActions';

Vue.component(Layout.name, Layout);
Vue.component(SubLayout.name, SubLayout);
Vue.component(AppUpload.name, AppUpload);
Vue.component(AppActions.name, AppActions);
Vue.component(AppSteps.name, AppSteps);
Vue.component(AppFormItem.name, AppFormItem);

// 注册请求组件
Vue.use(VueAxios, axios);
// 注册鉴权全局方法
Vue.use(sign);

// 配置全局 axios
axios.defaults.baseURL = '/api';
axios.defaults.timeout = 8 * 1000;
axios.interceptors.request.use(
  config => {
    config.headers.common['token'] = localStorage.getItem('token');
    return config;
  },
  error => {
    this.$toast.fail(error.message);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response.data.data;
  },
  error => {
    if (error.response) {
      if (error.response.status == 401) {
        router.replace('/login');
      } else {
        notification.error({ message: error.response.data.message });
      }
    } else {
      notification.error({ message: error.message });
    }
    return Promise.reject(error);
  }
);
