<template>
  <section class="app-steps" type="flex">
    <header class="app-steps-header">审批流程</header>
    <a-steps direction="vertical" :current="current - 1">
      <a-step
        v-for="(item, index) in responseSteps"
        :key="index"
        :title="item.title"
      >
        <template #description>
          <div class="description" @click="onClickStep(item, index)">
            <div>
              {{ item.detail }}
            </div>
            <div class="users">
              <span
                v-for="(item, index) in item.users"
                :key="index"
                style="margin-right: 8px;"
              >
                <a-badge v-if="item.audit" dot status="success">
                  <a-avatar
                    :src="item.avatar"
                    style="background-color: #1890ff"
                  >
                    {{ item.name.slice(1, 3) }}
                  </a-avatar>
                </a-badge>
                <a-avatar
                  v-else
                  :src="item.avatar"
                  style="background-color: #1890ff"
                >
                  {{ item.name.slice(1, 3) }}
                </a-avatar>
              </span>
              <a-icon
                v-if="!item.disabled"
                class="plus"
                type="plus-circle"
                theme="twoTone"
              />
            </div>
          </div>
        </template>
      </a-step>
    </a-steps>
  </section>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
import setting from '@/setting';

export default {
  name: 'AppSteps',
  props: {
    current: Number,
    steps: Array,
    divider: Boolean,
    disabled: Boolean
  },
  computed: {
    responseSteps() {
      if (this.disabled) {
        this.steps.forEach(item => {
          item.disabled = true;
        });
        return this.steps;
      } else {
        return this.steps;
      }
    }
  },
  methods: {
    async onClickStep(item, index) {
      try {
        if (item.disabled) {
          return;
        }
        // 鉴权
        await this.$sign();
        // 选人
        const { users } = await dd.biz.contact.complexPicker({
          title: '请选择审批人',
          corpId: setting.corpId,
          multiple: true,
          limitTips: '超出了',
          maxUsers: item.max,
          appId: setting.agentId,
          permissionType: 'GLOBAL',
          responseUserOnly: true,
          startWithDepartmentId: 0
        });
        this.$emit('change', { index, users });
      } catch (error) {
        console.error(error);
        await this.$notification.error({
          message: '错误',
          description: error.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-steps {
  &-header {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: bold;
  }

  .ant-steps {
    .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      cursor: pointer;

      .users {
        display: flex;
        align-items: center;
        margin: 8px;

        .plus {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
