<template>
  <a-layout>
    <a-layout-header>
      <div class="logo" />
      <a-menu
        theme="dark"
        mode="horizontal"
        v-model="active"
        @select="onSelectMenuItem"
      >
        <a-menu-item v-for="(item, index) in routes" :key="index">
          <a-icon :type="item.meta.icon" />
          {{ item.meta.title }}
        </a-menu-item>
      </a-menu>
    </a-layout-header>
    <router-view
      :key="active[0]"
      :title="title"
      :type="type"
      :routes="children"
    />
    <a-layout-footer>
      ©2020 Created by
    </a-layout-footer>
  </a-layout>
</template>

<script>
import setting from '@/setting';

export default {
  name: 'Layout',
  data() {
    const { name } = JSON.parse(localStorage.getItem('user'));
    return {
      active: undefined,
      route: undefined,
      title: undefined,
      type: undefined,
      children: undefined,
      name
    };
  },
  computed: {
    routes() {
      const { $router } = this;
      return $router.options.routes.filter(item => {
        const show = !item.meta.hidden;
        let allow = this.hasPermission(item);
        return show && allow;
      });
    }
  },
  watch: {
    $route: {
      handler: function(newValue, oldValue) {
        if (newValue != oldValue) {
          const { $route, routes } = this;
          const path = $route.path;
          const index = routes.findIndex(item => path.includes(item.path));
          this.active = [index];
          this.route = routes[index];
          this.title = this.route.meta?.title;
          this.type = this.route.meta.type;
          this.children = this.route.children;
        }
      },
      immediate: true
    }
  },
  methods: {
    async onSelectMenuItem({ key }) {
      const path = this.routes[key].path;
      if (key == 6) {
        await this.$router.push('/form');
      } else {
        await this.$router.push(path);
      }
    },
    hasPermission(item) {
      const role = item.meta.role;
      if (role) {
        return setting[role].includes(this.name);
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout {
  &-header {
    display: flex;
    align-items: center;

    .logo {
      width: 120px;
      height: 31px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 28px 16px 0;
      float: left;
    }

    .ant-menu {
      flex: 1 0;
      line-height: 64px;
    }
  }

  &-footer {
    text-align: center;
  }
}
</style>
