import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/components/Layout';
import SubLayout from '@/components/SubLayout';

Vue.use(Router);

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/pages/login'),
    meta: { title: '登录', hidden: true, icon: 'container' }
  },
  {
    path: '/',
    redirect: '/auditing',
    meta: { hidden: true }
  },
  {
    path: '/auditing',
    component: Layout,
    meta: { title: '我审批的', icon: 'appstore', type: 'audit' },
    children: [
      {
        path: '',
        component: SubLayout,
        meta: { title: '未审批', icon: 'container' },
        children: [
          {
            path: '',
            name: '/auditing',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/auditing/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'completed',
        component: SubLayout,
        meta: { title: '已审批', icon: 'container' },
        children: [
          {
            path: '',
            name: '/auditing/completed',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/auditing/completed/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      }
    ]
  },
  {
    path: '/launching',
    component: Layout,
    meta: { title: '我发起的', icon: 'appstore', type: 'launch' },
    children: [
      {
        path: '',
        component: SubLayout,
        meta: { title: '未审批', icon: 'container' },
        children: [
          {
            path: '',
            name: '/launching',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/launching/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'progressing',
        component: SubLayout,
        meta: { title: '审批中', icon: 'container' },
        children: [
          {
            path: '',
            name: '/launching/progressing',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/launching/progressing/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'completed',
        component: SubLayout,
        meta: { title: '已审批', icon: 'container' },
        children: [
          {
            path: '',
            name: '/launching/completed',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/launching/completed/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      }
    ]
  },
  {
    path: '/tasking',
    component: Layout,
    meta: { title: '我的任务', icon: 'appstore', type: 'task' },
    children: [
      {
        path: '',
        component: SubLayout,
        meta: { title: '指派中', icon: 'container' },
        children: [
          {
            path: '',
            name: '/tasking',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/tasking/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'completed',
        component: SubLayout,
        meta: { title: '已完成', icon: 'container' },
        children: [
          {
            path: '',
            name: '/tasking/completed',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/tasking/completed/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      }
    ]
  },
  {
    path: '/assigning',
    component: Layout,
    meta: {
      title: '任务指派',
      icon: 'appstore',
      type: 'assign',
      role: 'assignable'
    },
    children: [
      {
        path: '',
        component: SubLayout,
        meta: { title: '未指派', icon: 'container' },
        children: [
          {
            path: '',
            name: '/assigning',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/assigning/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'progressing',
        component: SubLayout,
        meta: { title: '指派中', icon: 'container' },
        children: [
          {
            path: '',
            name: '/assigning/progressing',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/assigning/progressing/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      },
      {
        path: 'completed',
        component: SubLayout,
        meta: { title: '已完成', icon: 'container' },
        children: [
          {
            path: '',
            name: '/assigning/completed',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/assigning/completed/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      }
    ]
  },
  {
    path: '/billing',
    component: Layout,
    meta: {
      title: '报账',
      icon: 'appstore',
      type: 'bill',
      role: 'billable'
    },
    children: [
      {
        path: '',
        component: SubLayout,
        meta: { title: '所有', icon: 'container' },
        children: [
          {
            path: '',
            name: '/billing',
            component: () => import('@/pages/main')
          },
          {
            path: 'detail/:id',
            name: '/billing/detail',
            props: true,
            component: () => import('@/pages/main/detail')
          }
        ]
      }
    ]
  },
  {
    path: '/form',
    component: Layout,
    meta: { title: '创建采购单', icon: 'appstore' },
    children: [
      {
        path: ':id?',
        name: '/form',
        props: true,
        component: () => import('@/pages/main/form'),
        meta: { icon: 'container' }
      }
    ]
  },
  {
    path: '*',
    component: import('@/pages/404'),
    meta: { title: '404', hidden: true }
  }
];

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    routes: constantRoutes
  });

const router = createRouter();

export default router;
