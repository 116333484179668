<template>
  <section class="app-upload" type="flex">
    <header v-if="!noShowHeader" class="app-upload-header">
      需要说明及其他
    </header>
    <div class="app-upload-body" v-if="length">
      <div class="image">
        <div
          class="item"
          v-for="(item, index) in images"
          :key="index"
          @click="onPreview(item)"
        >
          <a-icon type="file-image" />
          <span>{{ item.fileName }}</span>
        </div>
      </div>
      <div class="file">
        <div
          class="item"
          v-for="(item, index) in others"
          :key="index"
          @click="onPreview(item)"
        >
          <a-icon type="file" />
          <span>{{ item.fileName }}</span>
        </div>
      </div>
      <div class="space">
        <div
          class="item"
          v-for="(item, index) in spaces"
          :key="index"
          @click="onPreview(item)"
        >
          <a-icon type="profile" />
          <span>{{ item.fileName }}</span>
        </div>
      </div>
    </div>
    <div v-if="!disabled" @click="onUpload">
      <a-icon type="cloud-upload" style="font-size: 60px" />
    </div>
    <div v-if="!length" class="app-upload-footer">暂无附件</div>
  </section>
</template>

<script>
import _ from 'lodash';
import * as dd from 'dingtalk-jsapi';
import setting from '@/setting';

export default {
  name: 'AppUpload',
  props: {
    noShowHeader: Boolean,
    disabled: Boolean,
    value: [Object, String]
  },
  computed: {
    images() {
      return this.value?.image?.data;
    },
    others() {
      return this.value?.file?.data;
    },
    spaces() {
      return this.value?.space?.data;
    },
    length() {
      try {
        const images = this.value?.image?.data?.length > 0;
        const files = this.value?.file?.data?.length > 0;
        const spaces = this.value?.space?.data?.length > 0;
        return images || files || spaces;
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    async onUpload() {
      try {
        // 禁用
        if (this.disabled) {
          return;
        }
        // 鉴权
        await this.$sign();
        // 获得空间号
        const space = await this.$http.get('/upload/space');
        // 上传授权
        await this.$http.get('/upload/grant', { params: { type: 'add' } });
        const build = {
          multiple: true,
          compress: false,
          max: 9,
          isCopy: 1,
          spaceId: space.spaceid
        };
        // 上传附件
        const attachment = await dd.biz.util.uploadAttachment({
          types: ['photo', 'camera', 'file', 'space'],
          image: build,
          space: build,
          file: build
        });
        let e, v;
        if (attachment.type == 'image') {
          v = Object.assign({}, this.value, { image: attachment });
        }
        if (attachment.type == 'file') {
          v = Object.assign({}, this.value, { file: attachment });
        }
        if (attachment.type == 'space') {
          v = Object.assign({}, this.value, { space: attachment });
        }
        e = _.cloneDeep(v);
        this.$emit('input', e);
      } catch (error) {
        console.error(error);
        await this.$notification.error({
          message: '错误',
          description: error.message
        });
      }
    },
    async onPreview(item) {
      try {
        // 鉴权
        await this.$sign();
        // 下载授权
        await this.$http.get('/upload/grant', {
          params: { type: 'download', fileids: item.fileId }
        });
        // 预览
        await dd.biz.cspace.preview({
          corpId: setting.corpId,
          spaceId: item.spaceId,
          fileId: item.fileId,
          fileName: item.fileName,
          fileSize: item.fileSize,
          fileType: item.fileType
        });
      } catch (error) {
        console.error(error);
        await this.$notification.error({
          message: '错误',
          description: error.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-upload {
  &-header {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: bold;
  }

  &-body {
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .image,
    .file,
    .space {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        border-bottom: thin dotted #d9d9d9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 16px;
        cursor: pointer;

        .anticon {
          margin-right: 8px;
        }
      }
    }
  }

  &-footer {
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
