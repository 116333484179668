<template>
  <a-space size="large">
    <a-button icon="arrow-left" size="large" @click="$router.back()">
      返回
    </a-button>
    <a-button
      v-if="quit"
      icon="rollback"
      size="large"
      @click="showModal('quit')"
    >
      退回
    </a-button>
    <a-button
      v-if="reject"
      icon="close"
      size="large"
      type="danger"
      @click="showModal('reject')"
    >
      拒绝
    </a-button>
    <a-button
      v-if="agree"
      icon="check"
      size="large"
      type="primary"
      @click="showModal('agree')"
    >
      同意
    </a-button>
    <a-button
      v-if="reedit"
      icon="edit"
      size="large"
      type="primary"
      @click="onReedit"
    >
      重新编辑
    </a-button>
    <a-button
      v-if="download"
      icon="download"
      size="large"
      :loading="loading"
      @click="onDownload"
    >
      导出
    </a-button>
    <a-button
      v-if="complete"
      icon="check"
      size="large"
      type="primary"
      @click="showModal('complete')"
    >
      完成
    </a-button>
    <a-button
      v-if="reject2"
      icon="close"
      size="large"
      type="danger"
      @click="showModal('reject2')"
    >
      拒绝
    </a-button>
    <a-button
      v-if="assign"
      icon="tag"
      size="large"
      type="primary"
      @click="onAssign('assign')"
    >
      指派
    </a-button>
    <a-button
      v-if="replace"
      icon="tag"
      size="large"
      type="primary"
      @click="onAssign('replace')"
    >
      更换执行人
    </a-button>
    <a-button
      v-if="bill"
      icon="account-book"
      size="large"
      type="primary"
      @click="showModal('bill')"
    >
      完成报账
    </a-button>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="onOk"
      @cancel="onCancel"
    >
      <a-form-model
        ref="form"
        :model="model"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item v-if="reasonable" label="理由" prop="reason">
          <a-textarea v-model="model.reason" />
        </a-form-model-item>
        <a-form-model-item
          v-if="mode == 'complete'"
          label="实际单价"
          prop="actualPrice"
        >
          <a-input v-model="model.actualPrice" />
        </a-form-model-item>
        <a-form-model-item
          v-if="mode == 'complete'"
          label="实际金额"
          prop="actualAmount"
        >
          <a-input v-model="model.actualAmount" />
        </a-form-model-item>
      </a-form-model>
      <p>{{ text }}</p>
    </a-modal>
  </a-space>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
import setting from '@/setting';

export default {
  name: 'AppActions',
  props: {
    id: String,
    type: String,
    auditStatus: Number,
    assignStatus: Number,
    billStatus: Number
  },
  data() {
    return {
      mode: '',
      visible: false,
      loading: false,
      model: {
        reason: '',
        actualPrice: '',
        actualAmount: ''
      },
      rules: {
        actualPrice: { required: true, message: '实际单价是必填的' },
        actualAmount: { required: true, message: '实际金额是必填的' }
      },
      assigner: ''
    };
  },
  computed: {
    quit() {
      const { type, auditStatus } = this;
      const audit = type == 'audit';
      const launch = type == 'launch';
      const unaudit = auditStatus < 2;
      return (audit || launch) && unaudit;
    },
    reject() {
      return this.quit;
    },
    agree() {
      return this.quit;
    },
    reedit() {
      const { type, auditStatus } = this;
      const launch = type == 'launch';
      const reedit = auditStatus == 4;
      return launch && reedit;
    },
    download() {
      const { type } = this;
      const task = type == 'task';
      return task;
    },
    complete() {
      const { type, assignStatus } = this;
      const task = type == 'task';
      const assigned = assignStatus == 1;
      return task && assigned;
    },
    reject2() {
      const { type, assignStatus } = this;
      const assign = type == 'assign';
      const unassign = assignStatus == 0;
      return assign && unassign;
    },
    assign() {
      return this.reject2;
    },
    replace() {
      const { type, assignStatus } = this;
      const assign = type == 'assign';
      const assigned = assignStatus == 1;
      return assign && assigned;
    },
    bill() {
      const { type, billStatus } = this;
      const bill = type == 'bill';
      const unbill = billStatus == 0;
      return bill && unbill;
    },
    reasonable() {
      const { mode } = this;
      const modes = ['quit', 'reject', 'agree', 'reject2'];
      return modes.includes(mode);
    },
    title() {
      const { reasonable, mode } = this;
      if (reasonable) {
        return '请输入理由';
      } else if (mode == 'complete') {
        return '确定要完成吗？';
      } else if (mode == 'reject2') {
        return '确认要拒绝吗？';
      } else {
        return '提示';
      }
    },
    text() {
      const { mode, assigner } = this;
      if (this.mode == 'reject2') {
        return '错单、误审批、其他原因等';
      } else if (mode == 'assign' || mode == 'replace') {
        return `确定要指派给\`${assigner.name}\` 吗？`;
      } else if (mode == 'bill') {
        return '确定要完成报账吗？';
      } else {
        return '';
      }
    },
    req() {
      const { id, model, mode, assigner } = this;
      const urls = {
        quit: '/orders/back',
        reject: '/orders/audit',
        agree: '/orders/audit',
        complete: '/orders/finish',
        reject2: '/orders/assignFail',
        assign: '/orders/assign',
        replace: '/orders/assign',
        bill: '/bills/completeTheBill'
      };
      const audit = mode == 'agree';
      return {
        url: urls[mode],
        data: { id, audit, ...model, orderID: id, userID: assigner.emplId }
      };
    }
  },
  methods: {
    showModal(mode) {
      this.mode = mode;
      this.visible = true;
    },
    async onOk() {
      const { $router, $refs, $http, req } = this;
      try {
        this.loading = true;
        await $refs.form.validate();
        await $http.post(req.url, req.data);
        await $router.back();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.visible = false;
    },
    async onDownload() {
      const { $message, $http, id } = this;
      try {
        this.loading = true;
        await $http.get('/orders/download', { params: { id } });
        await $message.success({ content: '请到钉钉消息中查看' });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onAssign(mode) {
      try {
        this.mode = mode;
        // 鉴权
        await this.$sign();
        // 选人
        const { users } = await dd.biz.contact.complexPicker({
          title: '请选择审批人',
          corpId: setting.corpId,
          multiple: false,
          limitTips: '超出了',
          maxUsers: 1,
          appId: setting.agentId,
          permissionType: 'GLOBAL',
          responseUserOnly: true,
          startWithDepartmentId: 0
        });
        this.assigner = users[0];
        console.log(this.assigner);
        this.visible = true;
      } catch (error) {
        console.error(error);
      }
    },
    async onReedit() {
      await this.$router.push({
        name: '/form',
        params: { id: this.id }
      });
    }
  }
};
</script>
