import Vue from 'vue';

function status(value, type) {
  if (value == undefined) return '';
  const values = {
    audit: ['未审批', '审批中', '已审批', '已拒绝', '已退回'],
    assign: ['未指派', '指派中', '已完成'],
    bill: ['未报账', '报账中', '完成报账']
  };
  return values[type][value];
}

function color(value, type) {
  if (value == undefined) return '';
  const values = {
    audit: ['', '#faad14', '#52c41a', '#f5222d', '#faad14'],
    assign: ['', '#faad14', '#52c41a'],
    bill: ['', '#52c41a', '#52c41a'],
    timeout: ['']
  };
  return values[type][value];
}

function boolean(value) {
  if (value) return '超时';
}

Vue.filter('status', status);
Vue.filter('color', color);
Vue.filter('boolean', boolean);
